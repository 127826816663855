#caseStudyContainer {
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-weight: 300;
    background-size: 'contain';
}

#caseStudyTitle {
    b {
        font-weight: bold;
        font-size: 30px;
    }


    hr {
        border: 2px solid black;
        border-radius: 7px;
        width: 30%;
        margin-left: 0px;
    }
}

#caseStudyImageContainer {
    .caseStudyImageCol {
        width: 45% !important;
        max-width: 45% !important;
    }

    img {
        width: 100%;
        height: auto;
    }
}

@media screen and (max-width: 700px) and (min-width: 300px) {
    .cardContent {
        max-width: 100% !important;
        margin-bottom: 40px;
    }

    #caseStudyImageContainer {
        .caseStudyImageCol {
            width: 100% !important;
            max-width: 100% !important;
            margin-bottom: 20px;
        }
    
        img {
            width: 100%;
            height: auto;
        }
    }

    #caseStudyContainer {
        background-size: 'cover';
    }
}

@media screen and (max-width: 1200px) and (min-width: 700px) {

}
