#adminHeaderLogo {
    width: 12rem;
}

nav {
    background-color: #ffd907;

    .active {
        border-bottom: 1px solid gray;
    }
}
