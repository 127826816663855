#personal_banner_section{
	background-color: #ffffff;
}
#personal_banner_content{
	padding: 60px 0 0 0;
}

#gotoForm{
    cursor: pointer;
    color: #ffd907;
}

#personal_left {
    background-color: #ffffff;
    background-image: yellowBG;
    h1{
        color: #3e3e37;
        font-weight: bold;        
        font-size: 36px;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    }

    #fleetifyText{
        // text-transform: uppercase;
        font-weight: bold;    
        color: #ffd907;
    }

    p{
        color: #3e3e37;
        font-size: 20px;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    }

    Button{
        color: #ffffff;
        background-color: #3e3e37;
        border-radius: 50px;
        border: #ffffff;
    }

    #personalBannerText{
        padding: 100px 50px;
        // background-color: #ffd907;
        // margin: 150px 0 150px 0;
    }
}

#personal_right{
	flex: 1;

    #personal_banner_img {
        width: 90%;
        height: auto;
    }
}
