#banner_section{
	// background-color: #ffd907;   
    background-color: #ffffff;
}

#banner_content{
	padding: 60px 0 0 0;
}

#left {
    background-color: #ffd907;
    background-image: yellowBG;
    h1{
        color: #3e3e37;
        font-weight: bold;        
        font-size: 36px;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        #upperText{
            text-transform: uppercase;
        }
    }

    p{
        color: #3e3e37;
        font-size: 20px;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    }

    Button{
        color: #ffffff;
        background-color: #3e3e37;
        border-radius: 50px;
        border: #ffffff;
    }

    #bannerText{
        padding: 100px 50px;
        // background-color: #ffd907;
        // margin: 150px 0 150px 0;
    }
}

#right{
	flex: 1;
	background-color: #ffffff;
    // z-index: 3;
    // padding: 50px 0 0 0;
    // background-color: #ffd907;

    img {
        position: relative;
        padding-top: 50px;
        padding-bottom: 50px;
        width: 100%;
        height: auto;
    }
}

@media screen and (max-width: 700px) and (min-width: 300px) {
    #banner_content{
	    padding: 0;
    }
}

@media screen and (max-width: 1200px) and (min-width: 700px) {

}