#overviewContainer {
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-weight: 300;
}

#overviewIntro {
    b {
        font-weight: bold;
        font-size: 30px;
    }

    p {
        text-align: justify;
    }

    hr {
        border: 2px solid black;
        border-radius: 7px;
        width: 30%;
        margin-left: 0px;
    }
}

#overviewGrafikImage {
    width: 100%;
    height: auto;
}

.cardContent {
    background-color: rgba(251, 211, 51, 0.53);
    color: black;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    padding: 30px;
    width: 45% !important;
    max-width: 45% !important;

    p {
        font-size: 22px;
        text-align: center;
    }
}

#overviewCard {
    margin-left: auto;
    margin-right: auto;
}

.cardContentTitle {
    font-size: 30px;
    text-align: center;
    font-weight: bold;
}

.card-image {
    width: 90%;
    height: auto;
    margin-left: 20px;
    margin-right: auto;
    text-align: center;
}

@media screen and (max-width: 700px) and (min-width: 300px) {
    .cardContent {
        max-width: 100% !important;
        margin-bottom: 40px;
    }
}

@media screen and (max-width: 1200px) and (min-width: 700px) {

}