#downloadContent {
    .downloadTitle {
        font-size: 30px;
        color: #505D63;
    }

    button {
        color: #ffffff;
        background-color: #FBD333;
        border-radius: 10px;
        border: #ffffff;
        font-size: 24px;
        padding: 10px 40px;
        margin-top: 20px;
    }

    Button:hover{
        color: #e7e7ee;
        background-color: #bb9a15;
        border: #ffffff;
    }

    hr {
        border: 2px solid black;
        border-radius: 7px;
        width: 50%;
        margin-left: 0px;
    }
}