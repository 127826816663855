#homeBlogBannerHeader{
	padding: 60px 0 0 0;
}

#homeBlogBanner {
    background-size: contain;
}

#logo_img {
    width: 50%;
    height: auto
}

#homeBannerCaption {
    h1{
        color: #3e3e37;
        font-size: 40px;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        #upperText{
            text-transform: uppercase;
        }
    }

    h2{
        color: #3e3e37;
        font-weight: bold;
        font-size: 36px;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        #upperText{
            text-transform: uppercase;
        }
    }

    p{
        color: #3e3e37;
        font-size: 24px;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    }

    Button{
        color: #5b5b5b;
        background-color: #FBD333;
        border-radius: 10px;
        border: #ffffff;
        font-size: 24px;
        padding: 10px 40px;
    }

    Button:hover{
        color: #e7e7ee;
        background-color: #bb9a15;
        border: #ffffff;
    }

    #bannerThumbnailText {
        color: #ffffff;
        font-weight: bold;
        font-size: 28px;
    }

    a {
        font-weight: bold;
        color: #ffffff;
        font-size: 24px;
        text-decoration: none;
        border-bottom: 1px solid #ffffff;
        text-shadow: 2px 2px rgba(0, 0, 0, 0.25);
        box-shadow: 0px 2px rgba(0, 0, 0, 0.25);
    }

    padding: 100px 50px;
}

#homeBlogBannerImage{
	flex: 1;

    img {
        position: relative;
        padding-top: 50px;
        padding-bottom: 50px;
        width: 100%;
        height: auto;
    }
}

@media screen and (max-width: 700px) and (min-width: 300px) {
    #homeBlogBannerHeader{
	    padding: 0;
    }

    #homeBlogBanner {
        background-size: cover;
    }

    #logo_img {
        padding-left: 0px !important;
    }

    #homeBlogCompanyLogo {
        margin-left: 0px !important;
        padding-left: 0px !important
    }

    #homeBannerCaption {
        h1{
            color: #3e3e37;
            font-size: 40px;
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            #upperText{
                text-transform: uppercase;
            }
        }
    
        h2{
            color: #3e3e37;
            font-weight: bold;
            font-size: 36px;
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            #upperText{
                text-transform: uppercase;
            }
        }
    
        p{
            color: #3e3e37;
            font-size: 24px;
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        }
    
        Button{
            color: #5b5b5b;
            background-color: #FBD333;
            border-radius: 10px;
            border: #ffffff;
            font-size: 24px;
            padding: 10px 40px;
        }
    
        Button:hover{
            color: #e7e7ee;
            background-color: #bb9a15;
            border: #ffffff;
        }
    
        #bannerThumbnailText {
            color: #ffffff;
            font-weight: bold;
            font-size: 28px;
        }
    
        a {
            font-weight: bold;
            color: #ffffff;
            font-size: 24px;
            text-decoration: none;
            border-bottom: 1px solid #ffffff;
            text-shadow: 2px 2px rgba(0, 0, 0, 0.25);
            box-shadow: 0px 2px rgba(0, 0, 0, 0.25);
        }
    
        padding: 20px 20px;
    }
}

@media screen and (max-width: 1200px) and (min-width: 700px) {
    #homeBannerCaption {
        h1{
            color: #3e3e37;
            font-size: 40px;
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            #upperText{
                text-transform: uppercase;
            }
        }
    
        h2{
            color: #3e3e37;
            font-weight: bold;
            font-size: 36px;
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            #upperText{
                text-transform: uppercase;
            }
        }
    
        p{
            color: #3e3e37;
            font-size: 24px;
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        }
    
        Button{
            color: #5b5b5b;
            background-color: #FBD333;
            border-radius: 10px;
            border: #ffffff;
            font-size: 24px;
            padding: 10px 40px;
        }
    
        Button:hover{
            color: #e7e7ee;
            background-color: #bb9a15;
            border: #ffffff;
        }
    
        #bannerThumbnailText {
            color: #ffffff;
            font-weight: bold;
            font-size: 28px;
        }
    
        a {
            font-weight: bold;
            color: #ffffff;
            font-size: 24px;
            text-decoration: none;
            border-bottom: 1px solid #ffffff;
            text-shadow: 2px 2px rgba(0, 0, 0, 0.25);
            box-shadow: 0px 2px rgba(0, 0, 0, 0.25);
        }
    
        padding: 20px 20px;
    }

    #homeBlogBannerImage{
        display: flex;
        flex: 1;
        align-items: center;
    
        img {
            position: relative;
            padding-top: 20px;
            padding-bottom: 20px;
            width: 70%;
            height: auto;
            margin-left: auto;
            margin-right: auto;
        }
    }

    #homeBlogBannerHeader{
        padding: 20px 0 0 0;
    }

}