.cardItem{
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 100%;
    background-color: #ffffff;
    font-size: 12px;
    box-shadow: 1px 1px 5px #888888;
}

.card-img-top{
    cursor: pointer;
}

// #cardPlayer-body{
//     height: 350px;
//     background-color: #fdee8f;
// }

.single-box{
	position: relative;
	margin-bottom: 30px;
}

.cardTitlesPlayer{
    color: #3e3e37;
    font-weight: bold;        
    font-size: 25px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    // text-transform: uppercase;
    // text-align: center;
}

.testimoniText{
    color: #3e3e37;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-style: italic;
    margin: 0;
    font-size: 15px;
    // font-weight: bold;
    // text-align: center;
}

.person{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-style: italic;
    margin: 0;
    font-size: 15px;
    // font-weight: bold;
    // text-align: center;
}

.companyName{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-style: italic;
    font-size: 15px;
    // font-weight: bold;
    // text-align: center;
}

.cardDescPlayer{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 15px;
    // text-align: center;
}

@media screen and (max-width: 700px) and (min-width: 300px) {
    .card-img-top{
        width: 100%;
        height: auto;
    }
}

@media screen and (max-width: 1200px) and (min-width: 700px) {
    .card-img-top{
        width: 100%;
        height: auto;
    }
}