.modal-header {
    img {
        margin-left: auto;
        // margin-left: auto;
    }
}

#submitForm {
    background-color: #ffd907;
    border-color: #ffd907;
    color: black;
}


input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

#logoModal {
    width: 70%;
    height: auto;
}