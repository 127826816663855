#loginFormContainer {
    margin-left: auto;
    margin-right: auto;
    padding: 3rem;
    width: 36rem;
}
#adminLoginForm {
    text-align: center;
}

#fleetify-login-logo {
    width: 100%;
    height: auto;
}

#submitArticleFormButton {
    background-color: #ffd907;;
    border: #ffd907;
    color: black;
}