.articleContainer {
    margin-left: auto !important;
    margin-right: auto !important;
    // padding-left: 6rem !important;
    // padding-right: 6rem !important;
}

#articleContent {
    p {
        color: #3e3e37 !important;
        font-family: 'Poppins', sans-serif !important;
        // font-size: 12px;
    }

    span {
        color: #3e3e37 !important;
        font-family: 'Poppins', sans-serif !important;
    }

    h1 {
        margin-bottom: 2rem;
        // font-size: 48pt;
        font-weight: bold;
    }

    h2 {
        // span {
        //     font-size: 36pt;
        //     margin-bottom: 2rem;
        // }
        // font-size: 36pt;
        margin-bottom: 2rem;
        color: #3e3e37 !important;
        font-family: 'Poppins', sans-serif !important;
        margin-top: 2rem;
        font-weight: bold;
    }

    h3 {
        // font-size: 32pt;
        margin-bottom: 2rem;
        font-weight: bold;
    }

    img {
        text-align: center !important;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 2rem;
        margin-bottom: 2rem;
        width: 80% !important;
    }
    color: #3e3e37 !important;
    font-family: 'Poppins', sans-serif !important;
}

@media screen and (max-width: 700px) and (min-width: 300px) {
    #articleContent {
        img {
            text-align: center !important;
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 100% !important;
        }
    }

}

@media screen and (max-width: 1200px) and (min-width: 700px) {
    .articleContainer {
        max-width: 890px;
    }

    #articleContent {
        img {
            text-align: center !important;
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 100% !important;
        }
    }
}

@media screen and (max-width: 1400px) and (min-width: 1200px) {
    .articleContainer {
        max-width: 890px;
    }

}
