#titleVideoSection{
	font-weight: bold;
	// text-transform: uppercase;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	font-size: 35px;
  	// text-decoration: underline;
  	// text-decoration-color: #ffd907;
  margin-top:50px;  
  margin-bottom:50px;
}

#videoCaption{
  background-color: #ffd907;
  #captionText{
   background-color: #ffd907;  

   h3{
        color: #3e3e37;
        text-transform: uppercase;
        font-weight: bold;        
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    }

    p{
        color: #3e3e37;
        font-size: 15px;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    }

  }

}

#player-wrapper {
  position: relative;
  align-items: center;
}

.react-player {
  position: relative;
  padding: auto;
}

#IntroductionVideos {
    padding: 30px 0px;
}

@media screen and (max-width: 700px) and (min-width: 300px) {
   #player-wrapper {
      position: relative;
      margin: 10px 0;
      align-items: center;
    }

    .react-player {
      position: relative;
      padding: auto;
      width: 100%;      
    }
}