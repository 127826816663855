.header{
    justify-content: space-between;
    align-items: center;
    padding: 25px;
    background-color: #ffffff;

    // .navbar-nav .nav-link{
        // color: #ffffff;
    // }
    
    .nav-link{
        font-size: 15px;
        padding: 20px;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-weight: bold;
    }

    .nav-link:hover{
        background-color: #ffd907;
    }

    .nav-link:focus{
        color: #ffffff;
    }

}

#nav{
    background-color: #ffffff;
    padding: 0 10px;
}