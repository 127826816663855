#footer{
	// margin-top: 20px;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	text-align: center;
	padding: 2px;
	background-color: #fae154;
	position: relative;
}

#footerContainer{
	// margin-top: 20px;
	padding-top: 10px;
	padding-bottom: 20px;
    border-top: 1px solid #f7ef05;
	background-color: #ffd907;
}

#footerImg{
	margin-top: 10px;
}

.float{
	position:fixed;
	width:65px;
	height:60px;
	bottom:50px;
	right:40px;
	text-align:center;
	// background-color:#0C9;
	// color:#FFF;
	// border-radius:50px;
	// box-shadow: 2px 2px 3px #999;
}

.my-float{
	margin-top:22px;
}

@media screen and (max-width: 700px) and (min-width: 300px) {
    .float{
		position:fixed;
		width:60px;
		height:60px;
		bottom:50px;
		right:20px;
		text-align:center;
		// background-color:#0C9;
		// color:#FFF;
		// border-radius:50px;
		// box-shadow: 2px 2px 3px #999;
	}

	.my-float{
		margin-top:22px;
	}
}

#footerBigText{
	font-weight: bold;
	font-family: fantasy;
	font-size: 35px;
}

#footerSmallText{
	color: #b7b5b5;
	font-size: 15px;
}

#contactUSfooter{
	border-radius: 50px;
    border-color: #ffd907;
	color: black;
	font-weight: bold;
	background-color: #ffd907;
}

#rightFooter {
    margin-bottom: -20px;

	#logo{
		margin-top: 100px;
	}
}

#footerCopyright{
	margin-bottom: 20px;
}

#textCopyright{
	// color: #979797;
	color: #414141;
	font-size: 10px;
}

.copyright{
    background-color: #ffd907;
    padding: 10px 0;
    p{
        font-size: 14px;
        color: black;
        text-align: center;
        margin: 0px;
    }
}

.footerText {
    // color: #8c8c8b;
	color: #000000;
}

.img-social {
    margin-right: 10px;
}

#socialContact {
    margin-top: 10px;
	color: #000000;
}

#companyNameFooter { 
    margin: 10px 0px;
    font-weight: bold;
}