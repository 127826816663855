.cardItem{
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 100%;
    background-color: #ffffff;
    font-size: 12px;
    box-shadow: 1px 1px 5px #888888;
}

.card-img-top{
    cursor: pointer;
}

.card-body{
    height: 150px;
}

.single-box{
	position: relative;
	margin-bottom: 30px;
}

.cardTitles{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    // text-transform: uppercase;
    text-align: center;
}

.italicTitle{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
    font-style: italic;
    text-align: center;
}

.cardDesc{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-align: center;
}

@media screen and (max-width: 700px) and (min-width: 300px) {
    .card-img-top{
        width: 100%;
        height: auto;
    }
}

@media screen and (max-width: 1200px) and (min-width: 700px) {
    .card-img-top{
        width: 100%;
        height: auto;
    }
}