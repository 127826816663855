#contact_us{
    padding-top: 25px;
    // background-image: url('../../../assets/images/FormImageBG.jpg');
    // -webkit-background-size: cover;
    // -moz-background-size: cover;
    // -o-background-size: cover;
    // background-size: cover;
    // filter: brightness(70%);
}

#background {
    position: absolute;
    // top: 0px;
    // left: 0px;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-image: url('../../../assets/images/FormImageBG.jpg');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    filter: brightness(90%);
}


#contactSection{
	font-weight: bold;
	// text-transform: uppercase;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	font-size: 35px;
  	// text-decoration: underline;
  	// text-decoration-color: #ffd907;
    // margin-top:50px;  
  	// margin-bottom:50px;
}

#form_content{
	// padding: 100px 0 0 0;

    #formBannerText{
        margin: 30px;
        background-color: #ffffff;
        text-align: center;
    }

    h1{
        margin-top: 200px;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-size: 65px
    }

    p{
        color: #c9c7c7;
    }

    @media screen and (max-width: 568px) {
        h1{
            margin-top: 20px;
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            font-size: 50px
        }
    }

    .form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #c9c7c7;
        opacity: 1; /* Firefox */
    }

    input:focus, textarea:focus, select:focus {
        outline-offset: 0px !important;
        outline: none !important;
        border: 1px solid #ffd907 !important;
        box-shadow: 0 0 3px #ffd907 !important;
    }

    .form-control {
        padding: 20px;
        margin-top: 30px;
        margin-right: 30px;
        border-color: #ffd907;
    }

    #send{
        // border-radius: 50px;
        font-weight: bold;
        color: #ffffff;
        border-color: #ffd907;
        background-color: #ffd907;
        padding: auto;
    }

}

.back-to-top{
	position:relative;
	width:60px;
	height:60px;
    left: 10px;
    top: -1px;

    .arrow-up {
        margin-top:22px;
    }

    #backToTop{
        margin: 0 auto;
        border-radius:10px;
        background-color:#ffd907;
        border-color: #ffd907;
        width:60px;
	    height:60px;
    }
    img{
        cursor: pointer;
    }
}

small{
    display: block;
    color: #ffffff;
    font-weight: bold;
}


@media screen and (max-width: 700px) and (min-width: 300px) {
    #contact_us{
        background-image: url('../../../assets/images/FormImageBG.jpg');
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        padding-top: 25px;
    }
    .back-to-top{
        cursor: pointer;
        position:relative;
        width:60px;
        height:60px;
        top: -1px;
        // background-color:#ffd907;
        // border-color: #ffd907;

        .arrow-up{
            margin-top:22px;
        }

        #backToTop{
            border-radius:10px;
            background-color:#ffd907;
            border-color: #ffd907;
            width:60px;
            height:60px;
        }
    }
}

@media screen and (max-width: 1500px) and (min-width: 700px) {
     #contact_us{
        background-image: url('../../../assets/images/FormImageBG.jpg');
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        padding-top: 25px;
    }
}


