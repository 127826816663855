.main-template-wrapper{
    display: flex;
    flex-direction: column;
    height: 100vh;
    .content-wrapper{
        // background-image: url('../../assets/images/fleetify_bg_image.png');
        margin: auto;
        width: 100%;
        flex: 1;
    }
}