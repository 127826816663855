#articleFormContainer {
    #articleFormImage {
        width: 28rem;
        float: right
    }

    #submitArticleFormButton {
        float: right;
        margin-right: 1rem;
        background-color: #F6AB2D;
        border: none;
        box-shadow: 2px 2px gray;
    }
}

@media screen and (max-width: 700px) and (min-width: 300px) {
    #articleFormContainer {
        #rightFormCol {
            display: none;
        }
    }

}

@media screen and (max-width: 1200px) and (min-width: 700px) {
    #articleFormContainer {
        #articleFormImage {
            width: 18rem;
        }
    }
}