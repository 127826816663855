#fleetImgLeft{
	max-width: 100%;
    height: auto;
    margin: auto;
}

#textLeft{
	margin: 50px;

    #textLeftTitle{
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	text-transform: uppercase;
	font-weight: bold;
	color: #2f62a2;
	margin-bottom: 5px;
    }

    h2{
	text-transform: capitalize;
	font-weight: bold;
	color: #393636;
    }

    #textLeftDesc{
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	font-weight: lighter;
	// color: #9c9a9a;
    }

}

#fleetImgRight{
	max-width: 100%;
    height: auto;
    margin: auto;
}

#textRight{
	margin: 50px;

    #textRightTitle{
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	text-transform: uppercase;
	font-weight: bold;
	color: #2f62a2;
	margin-bottom: 5px;
    }

    h2{
	text-transform: capitalize;
	font-weight: bold;
	color: #393636;
    }

     #textRightDesc{
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	font-weight: lighter;
	// color: #5a5a5a;
    }
}

.what-we-do{
    margin-top: 20px;
}