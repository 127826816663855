#bannerHeader{
	padding: 60px 0 0 0;
}

#blogBanner {
    background-size: contain;
}

#logo_img {
    width: 50%;
    height: auto
}

#bannerCaption {
    h1{
        color: #3e3e37;
        font-weight: bold;        
        font-size: 48px;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        #upperText{
            text-transform: uppercase;
        }
    }

    p{
        color: #3e3e37;
        font-size: 24px;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    }

    Button{
        color: #5b5b5b;
        background-color: #FBD333;
        border-radius: 10px;
        border: #ffffff;
        font-size: 24px;
        padding: 10px 40px;
    }

    Button:hover{
        color: #e7e7ee;
        background-color: #bb9a15;
        border: #ffffff;
    }

    #bannerThumbnailText {
        color: #FF9900;
        font-weight: bold;
        font-size: 28px;
    }

    padding: 100px 50px;
}

#bannerImage{
	flex: 1;

    img {
        position: relative;
        padding-top: 50px;
        padding-bottom: 50px;
        width: 100%;
        height: auto;
    }
}

@media screen and (max-width: 700px) and (min-width: 300px) {
    #bannerHeader{
	    padding: 0;
    }

    #blogBanner {
        background-size: cover;
    }

    #logo_img {
        padding-left: 0px !important;
    }

    #companyLogo {
        margin-left: 0px !important;
        padding-left: 0px !important
    }
}

@media screen and (max-width: 1200px) and (min-width: 700px) {

}