.testimoni-section-b{
  // margin-top: 10%;
  // margin-bottom: 10%;
  margin: 10%;
}

#testimoniCaptionB{
  #testimoniTextB{
    background-color: #fdee8f;  
    padding-top: 20px;
    padding-bottom: 20px;
        h4{
            color: #3e3e37;
            font-weight: bold;        
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        }

        p{
            color: #3e3e37;
            font-size: 15px;
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        }

  }

}

#player-wrapper {
  position: relative;
  align-items: center;
}

.react-player-testimoni-b {
  position: relative;
}

#videosTestimoni {
    padding: 30px 0px;
    background-color: #fdee8f; 
}

@media screen and (max-width: 700px) and (min-width: 300px) {
    .testimoni-section-b{
        margin: 0;
    }
   .react-player-testimoni-b {
        position: relative;
        max-height: 300px;
        // padding: auto;
    }
}