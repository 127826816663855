#titleArea{
	font-weight: bold;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	font-size: 35px;
  	// text-decoration: underline;
  	// text-decoration-color: #ffd907;
    // margin-top:50px;  
  	// margin-bottom:50px;
}

#workshopArea{
	padding-top: 60px;	
}
#sectionCaptionText{
  background-color: #ffd907;
  #captionTextNetworkArea{
		background-color: #ffd907;
		margin: 50px 0 50px 0;
		h3{
			color: #3e3e37;
			// text-transform: uppercase;
			// font-variant: small-caps;
			font-weight: bold;        
			font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
		}

		p{
			color: #3e3e37;
			font-size: 15px;
			font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
		}
	}
}

#workshopAreaIlustrasi{
    background-color: #ffd907;
	padding-top:50px;  
  	padding-bottom:50px;
}


#counterDesc{
	margin-top: -20px;
	color: #c9c7c7;
    font-size: 20px;
    text-align: center;
	font-weight: bolder;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

#clientArea{	
	margin: 30px auto; 
}

#inMoreThan{
	color: #c9c7c7;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

#totalArea{
	font-size: 25px;
	color: #5981b5;
	font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
	margin-right: 70px;
}