.header{
    justify-content: space-between;
    align-items: center;
    padding: 25px;
    background-color: #ffffff;

    // .navbar{
    //     box-shadow: 0 0 1px 1px #ffd907;
    // }

    .navbar-nav .nav-link{
        // color: #ffffff;
        padding-left: 20px;
        padding-right: 20px;
    }
    
    .nav-link{
        font-size: 15px;
        padding: 20px;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-weight: bold;
    }

    .nav-link:hover{
        background-color: #ffd907;
    }

    .nav-link:focus{
        color: #ffffff;
    }

    .nav-link .active{
        background-color: #ffd907;
    }

}

.navbar_container{
    padding-left: 0 ;
    padding-right: 0 ;
}

#nav{
    background-color: #ffffff;
    padding: 0 10px;
}

.nav-link {
    a {
        text-decoration: none;
        color: inherit;
    }
}