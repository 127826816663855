#titleProduct{
	font-weight: bold;
	// text-transform: uppercase;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	font-size: 35px;
  	// text-decoration: underline;
  	// text-decoration-color: #ffd907;
    // margin-top:50px;  
  	// margin-bottom:50px;
}
#captionTextSection{
    background-color: #ffd907;
    #captionTextProduct{
        background-color: #ffd907;
        h3{
            color: #3e3e37;
            // text-transform: uppercase;
            font-weight: bold;        
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        }

        p{
            color: #3e3e37;
            text-align: justify;    
            font-size: 15px;
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        }
    }
}

#product{    
    padding-top: 25px;	
}

@media screen and (max-width: 700px) and (min-width: 300px) {
    #controlTowerIlustrasi{
            background-color: #ffd907;
            padding-bottom: 10px;
            padding-top: 10px;

    }
}

@media screen and (max-width: 1200px) and (min-width: 700px) {
    #controlTowerIlustrasi{
            background-color: #ffd907;
            padding-bottom: 10px;
            padding-top: 10px;

    }
}    