#articleHeaderContainer {
    #headerArticleLogo {
        width: 10rem;
        float: right;
    }

    #backIcon {
        color: black;
    }

    #backIcon:hover {
        color: gray
    }

    .leftCol {
        padding-top: 0.5rem;
    }
}