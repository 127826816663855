#titleVideoSection{
	font-weight: bold;
	// text-transform: uppercase;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	font-size: 35px;
  	// text-decoration: underline;
  	// text-decoration-color: #ffd907;
  margin-top:50px;  
  margin-bottom:50px;
}

.video{
  padding: 50px 0px;
}

#videoCaption{
  background-color: #ffd907;
  #captionText{
   background-color: #ffd907;  

   h3{
        color: #3e3e37;
        text-transform: uppercase;
        font-weight: bold;        
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    }

    p{
        color: #3e3e37;
        font-size: 15px;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    }

  }

}

#player-wrapper {
  position: relative;
  // padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */;
  // background-color: #fff8eb;
  align-items: center;
}

.react-players {
  position: relative;
  padding: auto;
  width: 100%;
  height:700px;
}

@media screen and (max-width: 700px) and (min-width: 300px) {
   #player-wrapper {
      position: relative;
      margin: 10px 0;
      // padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */;
      // background-color: #fff8eb;
      align-items: center;
    }

    .react-players {
      position: relative;
      padding: auto;
      width: 100%;      
    }
}

@media screen and (max-width: 1200px) and (min-width: 700px) {
    #player-wrapper {
      position: relative;
      margin: 10px 0;
      // padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */;
      // background-color: #fff8eb;
      align-items: center;
    }
}