#recommendationContainer {
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-weight: 300;
    background-size: 'contain';
}

#recommendationContent {
    b {
        font-weight: bold;
        font-size: 30px;
        text-align: right;
    }

    p {
        text-align: justify;
    }

    hr {
        border: 2px solid black;
        border-radius: 7px;
        width: 60%;
        margin-right: 0px;
    }
}

.recommendationTitle {
    display: block;
    text-align: right;
}

#reccomendationTextSection {
    padding-top: 150px;
}

#recommendationImage {
    width: 100%;
    height: auto
}

@media screen and (max-width: 700px) and (min-width: 300px) {
    #reccomendationTextSection {
        padding-top: 40px;
    }
}