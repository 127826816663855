.thumbnailArticle {
    object-fit: cover;
    width: 322px;
    height: 200px;
}


.articleContent {
    .articleLinkContainer {
        a {
            font-weight: bold;
            color: #FFC727;
            font-size: 24px;
            text-decoration: none;
            border-bottom: 1px solid #FFC727;
            box-shadow: 0px 2px rgba(0, 0, 0, 0.25);
            float: right;
        }

        .deleteButton {
            font-weight: bold;
            color: #dc3545;
            font-size: 24px;
            text-decoration: none;
            border-bottom: 1px solid #dc3545;
            box-shadow: 0px 2px rgba(0, 0, 0, 0.25);
            float: right;
        }

        .publishButton {
            font-weight: bold;
            color: #198754;
            font-size: 24px;
            text-decoration: none;
            border-bottom: 1px solid #198754;
            box-shadow: 0px 2px rgba(0, 0, 0, 0.25);
            float: right;
        }

        .editButton {
            font-weight: bold;
            color: #0dcaf0;
            font-size: 24px;
            text-decoration: none;
            border-bottom: 1px solid #0dcaf0;
            box-shadow: 0px 2px rgba(0, 0, 0, 0.25);
            float: right;
        }

        .draftButton {
            font-weight: bold;
            color: #6c757d;
            font-size: 24px;
            text-decoration: none;
            border-bottom: 1px solid #6c757d;
            box-shadow: 0px 2px rgba(0, 0, 0, 0.25);
            float: right;
        }

    }

    height: 100%;
}

.card-article {
    max-width: 31% !important;
    height: 31rem;
    padding: 10px;
}

.entryBody {
    height: 20rem;
}

.articleTitleContainer {
    margin-top: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;

    a {
        text-decoration: none;
        color: black !important; 
    }
}

.articleDescriptionContainer {
    margin-top: 20px;
    height: 6.3rem;
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* number of lines to show */
    -webkit-box-orient: vertical;
}

.articleLinkContainer {
    margin-top: 20px;
    position: absolute;
    bottom: 0;
    right: 0;
    padding-right: 0.8rem;
}

@media screen and (max-width: 700px) and (min-width: 300px) {
    .card-article {
        max-width: 100% !important;
        height: 31rem;
    }

}

@media screen and (max-width: 1200px) and (min-width: 700px) {
    .card-article {
        max-width: 31% !important;
        height: 22rem;
    }

    .thumbnailArticle {
        object-fit: cover;
        width: 193px;
        height: 100px;
    }

    .articleTitleContainer h2{
        font-size: 1.1rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        -webkit-box-orient: vertical;
    }
}