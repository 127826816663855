#introGrafik{
    img {
        position: relative;
        width: 100%;
        height: auto;
        padding: 20px 0px;
    }
}

#introContainer {
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-weight: 300;
    background-size: contain;
}

.grafikText {
    b {
        font-weight: bold;
    }

    p {
        text-align: justify;
    }
}

#introImage {
    img {
        position: relative;
        width: 100%;
        height: auto;
        padding: 20px 0px;
    }
}

#introSection3 {
    .grafikText {
        margin-top: auto;
        margin-bottom: auto;
        font-size: 20px;
    }
}

@media screen and (max-width: 700px) and (min-width: 300px) {
    #introContainer {
        background-size: cover;
    }
}

@media screen and (max-width: 1200px) and (min-width: 700px) {

}