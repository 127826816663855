.testimoni-section{
    margin-bottom: 5%;
}

.single-box{
	position: relative;
	margin-bottom: 30px;
}

.carousel-indicators{
	left: 0;
	top: auto;
	bottom: -20px;
}

#cardPlayer-body{
    background-color: #fdee8f;
}

.carousel-indicators li{
	background-color: #000;
	border-radius: 50%;
	width: 15px;
	height: 15px;
}

.carousel-indicators .active{
	background-color: #ffd907;
}

.rec .rec-pagination{
    margin: 20px;
}

.rec .rec-dot_active{
    background-color: #ffd907;
    box-shadow: 0 0 1px 3px #feb004;
}

.rec.rec-arrow{
    color: #ffd907;
}

.rec.rec-arrow:hover:disabled {
    border-radius: 0%;
}

.rec.rec-arrow:hover:enabled {
    border-radius: 0%;
    background-color: #ffd907;
}

.rec .rec-arrow-left {
    margin-left: 50px;
}

.rec .rec-arrow-right {
	margin-right: 50px;
}

.card-body{
	height: auto;
}

.card-header{
	background-color: #ffff;
	#titleToggle{
		background-color: #ffd907;
		font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
		text-transform: uppercase;
		font-weight: bold;
	}
}

@media screen and (max-width: 700px) and (min-width: 300px) {
    .rec .rec-arrow-left {
        visibility: collapse;
    }

    .rec .rec-arrow-right {
        visibility: collapse;
    }

	.rec .rec-arrow-left {
    	display: none;
	}

	.rec .rec-arrow-right {
		display: none;
	}
}

@media screen and (max-width: 1200px) and (min-width: 700px) {
    .rec .rec-arrow-left {
        visibility: collapse;
    }

    .rec .rec-arrow-right {
        visibility: collapse;
    }

	.rec .rec-arrow-left {
    	display: none;
	}

	.rec .rec-arrow-right {
		display: none;
	}
}

